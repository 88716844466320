<template>
  <CustomerSegment />
</template>

<script>
import CustomerSegment from '@/components/pages/customer/CustomerSegment'

export default {
  components: {
    CustomerSegment
  }
}
</script>
