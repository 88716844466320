<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Customer" title="Customer Segments" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Create New"
        size="lg"
        outline
        @click="goToCreatePage"
      >
        <template v-slot:icon>
          <q-icon name="add_circle" size="md" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingLists"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            :filter="search"
            @onRequest="onRequest"
          >
            <template v-slot:top-left>
              <h6>
                <p class="text-grey-6" v-if="search">
                  <small> Search result for: "{{ search }}" </small>
                </p>
              </h6>
            </template>

            <template v-slot:top-right>
              <q-input
                dense
                rounded
                outlined
                debounce="300"
                v-model="search"
                placeholder="Search"
              >
                <template v-slot:append>
                  <q-icon
                    :name="search ? 'close' : 'search'"
                    :class="search && 'cursor-pointer'"
                    @click="onClearSearch"
                  />
                </template>
              </q-input>
            </template>

            <template v-slot:body-cell-segmentCode="props">
              <q-td :props="props">
                <div class="multiLineLabel">
                  <span class="textMaxLine">
                    {{ props.row.segmentCode }}
                  </span>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-segmentName="props">
              <q-td :props="props">
                <div class="multiLineLabel">
                  <span class="textMaxLine">
                    {{ props.row.segmentName }}
                  </span>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-campaignUsage="props">
              <q-td :props="props">
                <Badge
                  :variant="
                    props.row.campaignUsage === 'Y' ? 'success' : 'danger'
                  "
                  :text="props.row.campaignUsage === 'Y' ? 'Yes' : 'No'"
                />
              </q-td>
            </template>

            <template v-slot:body-cell-status="props">
              <q-td :props="props">
                <Badge
                  :variant="props.row.status === 'A' ? 'success' : 'danger'"
                  :text="props.row.status === 'A' ? 'Active' : 'Deleted'"
                />
              </q-td>
            </template>

            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <Button icon="more_vert" round flat>
                  <q-menu>
                    <q-list dense style="min-width: 200px" class="text-primary">
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenViewModal(props.row)"
                      >
                        <q-avatar icon="article" size="lg" />
                        <q-item-section>View</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="goToEditPage(props.row)"
                        v-if="
                          props.row.campaignUsage !== 'Y' &&
                          props.row.status === 'A'
                        "
                      >
                        <q-avatar icon="edit" size="lg" />
                        <q-item-section>Edit</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenDeleteModal(props.row)"
                        v-if="
                          props.row.campaignUsage !== 'Y' &&
                          props.row.status === 'A'
                        "
                      >
                        <q-avatar icon="delete" size="lg" />
                        <q-item-section>Delete</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </Button>
              </q-td>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Customer Segments</h5>
      <p>
        {{ HELP.CUSTOMER_SEGMENT.description }}
      </p>

      <q-separator class="q-my-md" />

      <div class="q-mb-md">
        <h6 class="text-sm mb-2">
          {{ HELP.CUSTOMER_SEGMENT.create.description }}
        </h6>
        <ol>
          <li v-for="list in HELP.CUSTOMER_SEGMENT.create.lists" :key="list.id">
            {{ list.text }}
          </li>
        </ol>
      </div>
    </div>
  </Modal>

  <ViewCustomerSegmentModal
    :show="showViewModal"
    :data="selectedData"
    @close="onCloseViewModal"
  />

  <DeleteCustomerSegmentModal
    :show="showDeleteModal"
    :data="selectedData"
    :loading="loadingDelete"
    @close="onCloseDeleteModal"
    @submit="onSubmitDeleteModal"
  />
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import Badge from '@/components/Badge'

import { HELP } from '@/constants'

import ViewCustomerSegmentModal from './components/ViewCustomerSegmentModal'
import DeleteCustomerSegmentModal from './components/DeleteCustomerSegmentModal'

import {
  FORMAT,
  VALIDATION,
  Toast,
  getIconUrl,
  fetchData,
  EXPORT
} from '@/tools'

export default {
  name: 'CustomerSegment',
  components: {
    PageHeader,
    PageContent,
    Button,
    Table,
    Modal,
    Badge,
    ViewCustomerSegmentModal,
    DeleteCustomerSegmentModal
  },
  setup() {
    const router = useRouter()
    const { showToast } = Toast()

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataDelete,
      error: errorDelete,
      loading: loadingDelete,
      remove: postDelete
    } = fetchData()

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const showModal = ref(false)
    const showViewModal = ref(false)
    const showDeleteModal = ref(false)
    const selectedData = ref(null)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const search = ref('')
    const filterForm = ref(null)
    const isCreateSuccess = ref(false)
    const salesTableData = ref([])
    const salesTableHeader = ref([
      {
        name: 'segmentCode',
        label: 'Segment Code',
        field: 'segmentCode',
        align: 'left',
        sortable: true,
        style: 'max-width: 200px',
        headerStyle: 'max-width: 200px'
      },
      {
        name: 'segmentName',
        label: 'Segment Name',
        field: 'segmentName',
        align: 'left',
        sortable: true,
        style: 'max-width: 300px',
        headerStyle: 'max-width: 300px'
      },
      {
        name: 'memberCount',
        label: 'Member Count',
        field: 'memberCount',
        align: 'center',
        sortable: true
      },
      {
        name: 'campaignUsage',
        label: 'Campaign Usage',
        field: 'campaignUsage',
        align: 'center',
        sortable: true
      },
      {
        name: 'status',
        label: 'Status',
        field: 'status',
        align: 'center',
        sortable: true
      },
      { name: 'action', align: 'right', label: '' }
    ])

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const goToCreatePage = () => {
      router.push({ name: 'customer_segment_create' })
    }

    const goToEditPage = data => {
      router.push({ name: 'customer_segment_update', params: { id: data?.id } })
    }

    const exportTable = () => {
      const exportContent = salesTableData.value?.map(item => ({
        ...item,
        status: item?.status === 'A' ? 'Active' : 'Deleted',
        campaignUsage: item?.campaignUsage === 'Y' ? 'Yes' : 'No'
      }))

      EXPORT.exportToExcel({
        header: salesTableHeader.value,
        data: exportContent,
        filename: 'customer_segment_lists'
      })
    }

    const onOpenViewModal = data => {
      showViewModal.value = true
      selectedData.value = data
    }

    const onCloseViewModal = () => {
      showViewModal.value = false
      selectedData.value = null
    }

    const onOpenDeleteModal = data => {
      showDeleteModal.value = true
      selectedData.value = data
    }

    const onCloseDeleteModal = () => {
      showDeleteModal.value = false
      selectedData.value = null
    }

    const onClearSearch = () => {
      search.value = ''
    }

    const onSubmitDeleteModal = data => {
      if (data) {
        postDelete(`v1/customer-segmentation/filtered/${data?.id}`)
      }
    }

    const getSegmentLists = () => {
      getLists('/v1/customer-segmentation/filtered')
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      getSegmentLists()
    }

    onBeforeMount(() => {
      getSegmentLists()

      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          salesTableData.value = []
        } else {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.message ??
                'There was a problem fetching customer segments.',
              'danger'
            )
          } else if (dataLists?.value) {
            salesTableData.value = dataLists?.value
              .sort((a, b) => (a.code < b.code ? -1 : 1))
              .map(item => ({
                id: item?.id,
                segmentCode: item?.code,
                segmentName: item?.name,
                memberCount: item?.customer_count ?? 0,
                campaignUsage: item?.campaign_tag,
                status: item?.status
              }))
          }
        }
      })

      watch([dataDelete, errorDelete, loadingDelete], () => {
        if (!loadingDelete.value) {
          if (errorDelete?.value) {
            showToast(
              errorDelete?.value?.errors
                ? errorDelete?.value?.errors[0]?.msg
                : errorDelete?.value?.message ??
                    'There was a problem deleting this segment.',
              'danger'
            )
          } else if (dataDelete?.value) {
            if (dataDelete?.value) {
              getSegmentLists()
              showDeleteModal.value = false
              showToast('Customer segment successfully deleted!', 'success')
            }
          }
        }
      })
    })

    return {
      HELP,
      hasData,
      showModal,
      showViewModal,
      showDeleteModal,
      selectedData,
      loadingLists,
      loadingDelete,
      limitPage,
      pageNo,
      totalCount,
      search,
      filterForm,
      isCreateSuccess,
      salesTableHeader,
      salesTableData,
      FORMAT,
      VALIDATION,
      getIconUrl,
      onToggleModal,
      goToCreatePage,
      goToEditPage,
      exportTable,
      onRequest,
      onOpenViewModal,
      onCloseViewModal,
      onOpenDeleteModal,
      onCloseDeleteModal,
      onSubmitDeleteModal,
      onClearSearch
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  display: block;
  list-style: decimal;
  padding: 0 0 0 40px;

  li {
    list-style-position: outside;
    padding-left: 10px;
  }
}
</style>
