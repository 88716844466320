<template>
  <Modal
    :header="data?.segmentName"
    :show="show"
    :loading="loadingLists"
    width="700px"
    cancelLabel="Ok"
    @close="close"
    closeOnly
  >
    <q-card flat>
      <q-card-section>
        <h6 class="bg-white relative z-10 w-20 ml-4 px-2">Details</h6>
        <div class="mx-1 p-4 column q-gutter-md border rounded border-gray">
          <div>
            <div class="item">
              <div>Segment Code:</div>
              <span>{{ segment?.segmentCode }}</span>
            </div>
            <div class="item">
              <div class="w-32">Segment Name:</div>
              <span>{{ segment?.segmentName }}</span>
            </div>
            <div class="item">
              <div class="w-32">Segment Remarks:</div>
              <span>{{ segment?.segmentRemarks }}</span>
            </div>
            <div class="item">
              <div class="w-32">Members Count:</div>
              <span>{{ segment?.segmentCount }}</span>
            </div>
            <div class="item">
              <div class="w-32">Status:</div>
              <span>
                <Badge
                  :variant="segment?.status === 'A' ? 'success' : 'danger'"
                  :text="segment?.status === 'A' ? 'Active' : 'Deleted'"
                />
              </span>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>

    <q-card flat>
      <q-card-section>
        <h6 class="bg-white relative z-10 w-20 ml-4 px-2">Filters</h6>
        <div class="mx-1 p-4 column q-gutter-md border rounded border-gray">
          <div class="column gap-4 md:gap-2">
            <div>
              <strong>Customer Type:</strong>
              {{ getCustomerTypeName(segment?.segmentCustomerType) }}
            </div>
            <div v-if="segment?.filters?.month">
              <strong>Purchase Month:</strong>
              <ul>
                <li>
                  <span class="text-xs"><strong>Month From:</strong></span>
                  {{
                    DATE.toFriendlyMonthYearName(segment?.filters?.month?.from)
                  }}
                </li>
                <li>
                  <span class="text-xs"><strong>Month To:</strong></span>
                  {{
                    DATE.toFriendlyMonthYearName(segment?.filters?.month?.to)
                  }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.date?.to">
              <strong>Purchase Time Frame:</strong>
              <ul>
                <li>
                  <span class="text-xs"><strong>Date From:</strong></span>
                  {{ DATE.toFriendlyDate(segment?.filters?.date?.from) }}
                </li>
                <li>
                  <span class="text-xs"><strong>Date To:</strong></span>
                  {{ DATE.toFriendlyDate(segment?.filters?.date?.to) }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.not_date">
              <strong>Did Not Buy Time Frame:</strong>
              <ul>
                <li>
                  <span class="text-xs"><strong>Date From:</strong></span>
                  {{ DATE.toFriendlyDate(segment?.filters?.not_date?.from) }}
                </li>
                <li>
                  <span class="text-xs"><strong>Date To:</strong></span>
                  {{ DATE.toFriendlyDate(segment?.filters?.not_date?.to) }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.stores">
              <strong>Stores:</strong>
              {{ segment?.filters?.stores?.join(', ') }}
            </div>
            <div v-if="segment?.filters?.product">
              <strong>Product:</strong>
              <ul
                v-for="(product, index) in segment?.filters?.product"
                :key="index"
              >
                <li>{{ getBoughtName(product?.bought) }}</li>
                <li class="ml-4 row items-center gap-2">
                  <div class="text-xs"><strong>SKU:</strong></div>
                  {{ product?.sku_id?.join(', ') }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.brand">
              <strong>Brand:</strong>
              <ul
                v-for="(brand, index) in segment?.filters?.brand"
                :key="index"
              >
                <li>{{ getBoughtName(brand?.bought) }}</li>
                <li class="ml-4 row items-center gap-2">
                  <div class="text-xs"><strong>Brand:</strong></div>
                  {{ brand?.brand_id?.join(', ') }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.product">
              <strong>Category:</strong>
              <ul
                v-for="(category, index) in segment?.filters?.category"
                :key="index"
              >
                <li>{{ getBoughtName(category?.bought) }}</li>
                <li class="ml-4 row items-center gap-2">
                  <div class="text-xs"><strong>Category:</strong></div>
                  {{ category?.category_id?.join(', ') }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.basket_size">
              <strong>Basket Size:</strong>
              <ul>
                <li>
                  {{ getLeastMostName(segment?.filters?.basket_size?.option) }}
                </li>
                <li>
                  {{
                    segment?.filters?.basket_size?.option === 'in-between'
                      ? `${segment?.filters?.basket_size?.value} - ${segment?.filters?.basket_size?.value2}`
                      : segment?.filters?.basket_size?.value
                  }}
                </li>
              </ul>
            </div>
            <div v-if="segment?.filters?.order_count">
              <strong>Order Count:</strong>
              <ul>
                <li>
                  {{ getLeastMostName(segment?.filters?.order_count?.option) }}
                </li>
                <li>
                  {{
                    segment?.filters?.order_count?.option === 'in-between'
                      ? `${segment?.filters?.order_count?.value} - ${segment?.filters?.order_count?.value2}`
                      : segment?.filters?.order_count?.value
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </Modal>
</template>

<script>
import { ref, watch, onBeforeMount, watchEffect } from 'vue'
import Modal from '@/components/Modal'
import Badge from '@/components/Badge'

import {
  optionsBought,
  optionsLeastMost,
  optionsMyNonCustomers
} from '@/constants'
import { Toast, fetchData, DATE } from '@/tools'

export default {
  name: 'ViewCustomerSegmentModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  components: { Modal, Badge },
  setup(props, { emit }) {
    const { showToast } = Toast()

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const segment = ref(null)

    const close = () => {
      emit('close')
    }

    const getCustomerSegment = id => {
      getLists(`/v1/customer-segmentation/filtered/${id}`)
    }

    const getBoughtName = data => {
      return optionsBought.filter(item => item.value === data)[0]?.label
    }

    const getLeastMostName = data => {
      return optionsLeastMost.filter(item => item.value === data)[0]?.label
    }

    const getCustomerTypeName = data => {
      return optionsMyNonCustomers.filter(item => item.value === data)[0]?.label
    }

    watchEffect(() => {
      if (props.show && props?.data?.id) {
        getCustomerSegment(props?.data?.id)
      }
    })

    onBeforeMount(() => {
      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          segment.value = null
        } else {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.message ??
                'There was a problem fetching customer segment.',
              'danger'
            )
          } else if (dataLists?.value) {
            const data = dataLists?.value

            segment.value = {
              segmentCode: data?.code,
              segmentName: data?.name,
              segmentRemarks: data?.remarks,
              filters: JSON.parse(data?.filters),
              segmentCount: data?.customer_count,
              segmentCustomerType: data?.customer_flag,
              status: data?.status,
              createdAt: data?.created_at
            }
          }
        }
      })
    })

    return {
      DATE,
      loadingLists,
      segment,
      close,
      getBoughtName,
      getLeastMostName,
      getCustomerTypeName
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  @apply mb-4;
  @apply flex flex-col gap-0;
  @apply md:mb-2 md:flex-row md:gap-2;

  > div {
    @apply w-32;
    @apply font-bold;
    min-width: 8rem;
  }
}

ul {
  display: block;
  list-style: disc outside none;
  padding: 0 0 0 40px;
}
</style>
