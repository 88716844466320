<template>
  <Modal
    header="Delete Customer Segment"
    :show="show"
    :loadingSubmit="loading"
    @close="close"
    @submit="submit"
  >
    <q-form ref="customerSegmentDeleteForm">
      <q-card flat>
        <q-card-section>
          <p>Are you sure you want to delete this customer segment?</p>
          <ul class="font-semibold mt-4">
            <li>{{ `${data?.segmentCode} - ${data?.segmentName}` }}</li>
          </ul>
        </q-card-section>
      </q-card>
    </q-form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'DeleteCustomerSegmentModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { Modal },
  setup(props, { emit }) {
    const close = () => {
      emit('close')
    }

    const submit = () => {
      const data = {
        id: props?.data?.id
      }
      emit('submit', data)
    }

    return {
      close,
      submit
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  list-style: disc outside none;
  padding: 0 0 0 40px;
}
</style>
